<template>
    <div class="pb-5">
        <div class="pt-2 card">
            <ScheduleModalEdit
                :modalShow="scheduleEditModal.showModal"
                :idCampaign="scheduleEditModal.idCampaign"
                :situationCampaign="scheduleEditModal.situationCampaign"
                :descriptionCampaign="scheduleEditModal.descriptionCampaign"
                :term="scheduleEditModal.term"
                @closeModalSchedule="closeModalSchedule"
                @canceledScheduleCampaign="closeModalSchedule"
                @savedSchedule="openModalSuccess"
            />

            <SucessoModal
                :modalShow="modalSuccess.showModal"
                :typeModal="modalSuccess.typeModal"
                :caseModal="modalSuccess.caseModal"
                @confirmed="closeModalSuccess"
            />

            <b-row>
                <b-col md="12" lg="6">
                    <h2 class="px-2">Lista de Campanhas</h2>
                </b-col>
            </b-row>
            <div v-if="canInsert" class="py-2 px-2">
               <b-button @click="redirectToRegister()" variant="primary-button">
                   <feather-icon
                       icon="PlusIcon"
                       class="mr-50"  
                   />
                   <span class="align-middle">Adicionar nova campanha</span>
               </b-button>
           </div>
            <Filters
                @actionSearch="handleSubmitFormFilters"
                @actionClear="clearFilters"
                >
                <validation-observer ref="formFilters">
                    <b-form>
                    <b-row class="px-2 pt-1">
                        <b-col
                        sm="12"
                        md="6"
                        lg="6"
                        >
                        <b-form-group
                            label="Nome da Campanha"
                            label-for="name"
                            label-class="font_size_label"
                        >
                            <validation-provider
                            #default="{ errors }"
                            name="name"
                            >
                            <b-form-input
                                id="name"
                                v-model="search.nome"
                                placeholder="Nome da Campanha"
                                autocomplete="off"
                                maxlength="200"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        </b-col>
                        <b-col sm="6" md="6" lg="4" xl="3">
                        <b-form-group
                            label="Data da Campanha"
                            label-for="data_campanha"
                            label-class="font_size_label"
                        >
                        <validation-provider
                                name="data_campanha"
                            >
                            <b-input-group>
                            <b-form-input
                                class="form-control"
                                id="data_campanha"
                                v-model="input.data_campanha"
                                v-mask="'##/##/####'"
                                placeholder="00/00/0000"
                                autocomplete="off"
                                @input="setInicioInput"
                            />
                            <b-input-group-append>
                                <b-form-datepicker
                                    class="form-control d-flex align-items-center"
                                    locale="pt-BR"
                                    v-model="search.data_campanha"
                                    button-only
                                    right
                                    :hide-header="true"
                                    label-help="Selecione uma data dentro do período da campanha"
                                    button-variant="custom-blue"
                                    clearable
                                    @input="setInicioPicker"
                                />
                            </b-input-group-append>
                            </b-input-group>
                        </validation-provider>
                        <small v-if="error.data_campanha" class="text-danger">A data informada é inválida</small>
                        </b-form-group>
                        </b-col>
                        <b-col
                        sm="6"
                        lg="3"
                        >
                        <b-form-group
                            label="Status"
                            label-for="active"
                            label-class="font_size_label"
                        >
                            <v-select
                            id="active"
                            v-model="search.situacao"
                            :options="statusForm"
                            variant="custom"
                            item-text="description"
                            item-value="id"
                            placeholder="Status"
                            label="description"
                            >
                            <template
                                v-slot:no-options
                            >
                                Nenhum registro encontrado.
                            </template>
                            </v-select>
                        </b-form-group>
                        </b-col>
                    </b-row>
                    </b-form>
                </validation-observer>
                </Filters>

            <TimedAlert class="mx-2" variant="custom-blue" :message="campaignNotScheduled"
                :show="campaignNotScheduled != ''" />

            <b-alert variant="primary" :show="tabela.erroTabela" class="mx-2 mt-5">
                <div class="alert-body d-flex justify-content-center">
                    <span class="text-primary">
                        <strong class="text-primary">Sistema de busca indisponível no momento.</strong>
                    </span>
                </div>
            </b-alert>

            <section class="filters pb-1 px-2" v-if="!loadingData">
                <div class="d-flex align-items-center">
                    <span class="mr-1" for="tableSize">Mostrar</span>
                    <v-select
                        id="tableSize"
                        v-model="paginationData.defaultSize"
                        @input="updateQtdView"
                        :options="tabela.quantidadeLinhasVisualizar"
                        :clearable="false"
                        style="width: 100px;"
                    />
                </div>
            </section>
            <div v-if="loadingTable" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <section class="list-campaign" v-if="!loadingTable">
                <b-table
                    id="tableCampaign"
                    responsive
                    :stickyColumn="true"
                    :busy.sync="tabela.tabelaOcupada"
                    :fields="tabela.fields"
                    :items="tabela.items"
                    @sort-changed="handleOrderTable"
                    no-local-sorting
                    :no-sort-reset="true"
                >
                    <template #cell(campanha_descricao)="row">
                        <span>{{ row.item.campanha_descricao }}</span>
                    </template>
                    <template #cell(campanha_cronograma)="row">
                        <span>
                          {{
                            cronogramaCampanha(
                                row.item.campanha_situacao,
                                row.item.inicio_campanha,
                                row.item.termino_campanha,
                            )
                          }}
                        </span>
                    </template>
                    <template #cell(campanha_situacao)="row">
                        <SituationBadge
                            :situation="row.item.campanha_situacao"
                            :key="row.item.id_campanha"
                        />
                    </template>
                    <template #cell(actions)="row">
                        <span class="mr-1">
                            <feather-icon
                                v-if="viewCampaign"
                                @click.prevent="redirectToShow(row.item)"
                                id="show-campanha"
                                role="button"
                                class="text-custom-blue"
                                icon="EyeIcon"
                                size="18"
                            />
                            <feather-icon
                                v-else
                                id="show-campanha"
                                role="button"
                                class="text-muted pointer-none"
                                icon="EyeIcon"
                                size="18"
                            />
                        </span>
                        <span v-if="arEditCampaign || drEditCampaign" class="mr-1">
                            <feather-icon
                                v-if="row.item.campanha_situacao == 'Cancelada' || row.item.campanha_situacao == 'Concluída'"
                                id="edit-campanha"
                                role="button"
                                class="text-muted pointer-none"
                                icon="EditIcon"
                                size="18"
                            />
                            <feather-icon
                                v-else
                                @click.prevent="redirectToEdit(row.item)"
                                id="edit-campanha"
                                role="button"
                                class="text-custom-blue"
                                icon="EditIcon"
                                size="18"
                            />
                        </span>
                        <span v-if="canEditCronograma">
                            <feather-icon
                                v-if="
                                        row.item.campanha_situacao == 'Agendada' || 
                                        row.item.campanha_situacao == 'Em andamento' || 
                                        row.item.campanha_situacao == 'Concluída'
                                    "
                                @click.prevent="openModalSchedule(row.item)"
                                id="edit-schedule-campaign"
                                role="button"
                                class="text-custom-blue"
                                icon="CalendarIcon"
                                size="18"
                            />
                            <feather-icon
                                v-else
                                id="edit-schedule-campaign"
                                role="button"
                                class="text-muted pointer-none"
                                icon="CalendarIcon"
                                size="18"
                            />
                        </span>
                    </template>
                </b-table>
                <div class="pb-2 px-1">
                    <span v-if="tabela.semDados" class="ml-1">Nenhuma campanha encontrada</span>
                    <b-col sm="12" class="mt-2">
                        <CustomPagination :paginacao="paginationData"
                            @page-cliked="updateCurrentPage" />
                    </b-col>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {
    BForm, BRow,  BCol, BBadge,
    BFormGroup, BFormInput, BFormSelect,
    BButton, BLink, BTable, BTableSimple,
    BTbody, BTr, BTd, BSpinner,BAlert,
    BIconPlusCircle, BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import SituationBadge from '@/views/components/custom/SituationCampagin/SituationBadges.vue';
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import moment from 'moment'
import { actions, subjects } from "@/libs/acl/rules";
import ScheduleModalEdit from '@/views/custom-pages/Campanhas/Schedule/CampaignScheduleEdit.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue';
import {tipoCampanhaEnum} from "@/enum/tipoCampanha";
import Filters from '@/views/components/custom/filters/Filters.vue';

export default {
    components: {
    BForm,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BIconPlusCircle,
    BButton,
    BLink,
    Cleave,
    vSelect,
    BTable,
    CustomPagination,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BAlert,
    BSpinner,
    SituationBadge,
    TimedAlert,
    ScheduleModalEdit,
    SucessoModal,
    TableFilter,
    Filters,
    ValidationObserver,
    ValidationProvider,
    BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
},

    props: ['nameSavedCampaign'],

    mounted() {
        this.findAll();
    },

    data() {
        return {
            moment,
            arEditCampaign: this.$can(actions.ATUALIZAR, subjects.CAMPANHAS_SESI_UO),
            drEditCampaign: this.$can(actions.ATUALIZAR, subjects.CAMPANHAS_SESI_DR),
            viewCampaign: this.$can(actions.VISUALIZAR, subjects.CAMPANHAS),
            canEditCronograma: this.$can(actions.ATUALIZAR, subjects.CRONOGRAMA_CAMPANHA),
            canInsert: this.$can(actions.GERENCIAR, subjects.CAMPANHAS_MENUS_ROTAS),

            registers: [],

            showTable: false,
            loadingTable: false,
            search: {
                nome: null,
                situacao: null,
                data_campanha: null,
            },
            input: {
                data_campanha: null,
            }, 
            paginationData: {
                currentPage: 1,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10,
            },

            scheduleEditModal: {
                showModal: false,
                idCampaign: null,
                situationCampaign: null,
                descriptionCampaign: null,
                term: null,
            },

            modalSuccess: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'edit-schedule'
            },
            error: {
                data_campanha: null,    
            },
            loadingData: true,
            tabela: {
                semDados: false,
                erroTabela: false,
                tabelaOcupada: false,
                quantidadeLinhasVisualizar: [10, 25, 50, 100],
                sort: '',
                columnOrder: '',
                fields: [
                    { key: 'campanha_descricao', label: 'NOME', sortable: true },
                    { key: 'campanha_cronograma', label: 'PERÍODO' },
                    { key: 'campanha_situacao', label: 'STATUS' },
                    { key: 'actions', label: 'AÇÕES', class: 'width-custom-column text-center actions' }
                ],
                items: [],
            },
            campaignNotScheduled: '',
            statusForm: ['Rascunho', 'Agendada', 'Em andamento', 'Concluída', 'Cancelada']
        }
    },

    methods: {
        async findAll(filter = false) {
            if(!filter){
                this.tabela.erroTabela = false
                this.tabela.semDados = false
                this.loadingTable = true
            }
            this.error.data_campanha = false

            await this.$http.get(this.$api.campanha(), { params: this.setParams() }).then(response => {
                if (response.status === 200) {
                    this.tabela.items = response.data.data
                    this.handlePagination(response.data)

                    if (response.data.data.length > 0) {
                        this.tabela.tabelaOcupada = false
                        return
                    }

                    this.tabela.semDados = true
                    this.showTable = false
                }
            }).catch(({ response: { data } }) => { 
                this.error.data_campanha = !!data.errors.dataCampanha
            })

            this.loadingTable = false;
            if(this.$props.nameSavedCampaign) {
                this.campaignNotScheduled = 'Campanha '+this.$props.nameSavedCampaign+' não lançada';
            }
        },

        handleOrderTable(context) {
            this.tabela.tabelaOcupada = true
            this.tabela.columnOrder = context.sortBy
            this.tabela.sort = context.sortDesc ? 'desc' : 'asc'

            this.findAll(true)
        },

        setParams() {
            return {
                colunaNome: this.tabela.columnOrder,
                colunaOrdem: this.tabela.sort,
                porPagina: this.paginationData.defaultSize,
                pagina: this.paginationData.currentPage,
                tipoCampanha: tipoCampanhaEnum.REGIONAL,
                situacao: this.search.situacao ? [this.search.situacao] : null,
                descricaoCampanha: this.search.nome,
                dataCampanha: this.search.data_campanha,
            }
        },

      cronogramaCampanha(situacao, inicioCampanha, terminoCampanha) {
          if(!situacao || situacao === 'Rascunho') {
            return '-'
          }

          const inicioCampanhaAux = inicioCampanha ? moment(inicioCampanha).format("DD/MM/YYYY HH:mm") : ''
          const terminoCampanhaAux = terminoCampanha ? moment(terminoCampanha).format("DD/MM/YYYY HH:mm") : ''

          return `${inicioCampanhaAux} - ${terminoCampanhaAux}`
      },

      handlePagination(data) {
        this.paginationData.fromLine = data.from
        this.paginationData.toLine = data.to
        this.paginationData.totalLines = data.total
        this.paginationData.currentPage = data.current_page
      },

      updateQtdView() {
        this.paginationData.currentPage = 1;
        this.findAll();
      },

        updateCurrentPage(page) {
            this.paginationData.currentPage = page
            this.findAll()
        },

        redirectToEdit({id_campanha}) {
            this.$router.push(
                {
                    name: 'campanha-edit',
                    params: {
                        idCampanha: id_campanha
                    }
                }
            );
        },

        redirectToShow({id_campanha}) {
            this.$router.push(
                {
                    name: 'campaign-show',
                    params: {
                        idCampaign: id_campanha
                    }
                }
            );
        },

        openModalSchedule({campanha_situacao, id_campanha, campanha_descricao, termo}) {
            this.scheduleEditModal.situationCampaign = campanha_situacao;
            this.scheduleEditModal.idCampaign = id_campanha;
            this.scheduleEditModal.descriptionCampaign = campanha_descricao
            this.scheduleEditModal.term = termo
            setTimeout(() => {this.scheduleEditModal.showModal = true;}, 50); 
        },

        closeModalSchedule() {
            this.scheduleEditModal.showModal = false;
        },

        closeModalSuccess() {
            this.modalSuccess.showModal = false;
        },

        openModalSuccess() {
            this.scheduleEditModal.showModal = false;
            this.modalSuccess.showModal = true;
            this.findAll();
        },
        handleSubmitFormFilters() {
        this.paginationData.currentPage = 1
        this.$refs.formFilters.validate()
            .then(success => {
            if (success) {
                this.findAll()
            }
            })
        },

        clearFilters() {
        this.paginationData.currentPage = 1
        this.search.nome = null
        this.search.situacao = null
        this.setInicioPicker(null)

        this.findAll()
        },
        setInicioInput(value) {
        this.input.data_campanha = value;
        this.search.data_campanha = value ? moment(this.input.data_campanha, 'DD/MM/YYYY').format('YYYY-MM-DD') : value;
        },
        setInicioPicker(value) {
        this.search.data_campanha = value;
        this.input.data_campanha = value ? moment(this.search.data_campanha).format('DD/MM/YYYY') : value;
        },
        redirectToRegister() {
            this.$router.push({name: 'register-campanha'});
        },
    }
}
</script>
<style>
    .pointer-none {
        pointer-events: none;
    }
    
    @media (max-width: 800px)
    {
        .actions {width: 19%;}
        .actions .mr-1 {margin-right: 10px !important;}
    }
</style>